<template>
  <div class="card card-body shadow-sm">
    <app-basic-table
      ref="basic_table"
      :table-name="$t('users.user list')"
      :filters.sync="filters"
      :setting-columns="columns"
      :endpoint-list="ENDPOINT.FACILITY_LIST"
      @resetAllSearch="onResetAllSearch"
      @searched="onSearch"
      @sortRowTable="onSortRowTable"
      :listCanSort="true"
    >
      <template v-slot:filters>
        <div class="row">
          <div class="col-xl-4 col-lg-4 col-sm-4">
            <div class="form-group">
              <label>{{ filters.apartment_id_equal.label }}</label>
              <app-select :name="filters.apartment_id_equal.name"
                          input-style="normal"
                          v-model="filters.apartment_id_equal.value"
                          :options-data="meta.apartments"
              />
            </div>
          </div>
          <div class="col-xl-4 col-lg-4 col-sm-4">
            <div class="form-group">
              <label>{{ filters.serial_id_like.label }}</label>
              <app-input :name="filters.serial_id_like.name" input-style="normal"
                         v-model="filters.serial_id_like.value"
              />
            </div>
          </div>
          <div class="col-xl-4 col-lg-4 col-sm-4">
            <div class="form-group">
              <label>{{ filters.facility_id_equal.label }}</label>
              <app-select :name="filters.facility_id_equal.name"
                          input-style="normal"
                          v-model="filters.facility_id_equal.value"
                          :options-data="meta.facilitiesName" />
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <label class="align-self-center mb-0 mr-5 pl-2">{{ filters.status_disp_crowded_equal.label }}</label>
          <div class="col-xl-5 col-lg-8 col-sm-8 gr-icheck mt-0">
            <app-radio v-for="(item, index) in FACILITIES_CONSTANT.filter_status_disp_crowded"
                       :isMarginLeft="index !=0 ? true : false" :key="item.id" :label="item.name"
                       :val="item.id" v-model="filters.status_disp_crowded_equal.value"/>
          </div>
        </div>
      </template>

      <template v-slot:table-menu-right>
        <button @click="handleBtnCreateClick()" class="btn btn-warning">
          {{ $t("common.create") }}
        </button>
      </template>

      <template v-slot:body-cell-apartment_id="props">
        <td class="app-align-middle">
          <p :data-original-title="getApartmentName(props.row.apartment_id)" style="color: #007bff;"
             @click="handlerApartmentNameClick(props.row)" class="apartment_name app-table-p app-cell-tooltip mb-0">
            {{getApartmentName(props.row.apartment_id)}}
          </p>
        </td>
      </template>


      <template v-slot:body-cell-auto_time_working="props">
        <td class="app-align-middle" v-bind:style="'background-color :' + FACILITIES_CONSTANT.time_working[props.row.auto_time_working].color">
          <p class="app-table-p app-cell-tooltip mb-0" style="color: white">
            {{FACILITIES_CONSTANT.time_working[props.row.auto_time_working].name}}
          </p>
        </td>
      </template>

      <template v-slot:body-cell-auto_status="props">
        <td class="app-align-middle" v-bind:style="'background-color :' + FACILITIES_CONSTANT.auto_status[props.row.auto_status].color">
          <p class="app-table-p app-cell-tooltip mb-0" style="color: white">
            {{FACILITIES_CONSTANT.auto_status[props.row.auto_status].name}}
          </p>
        </td>
      </template>


      <template v-slot:body-cell-auto_manage_type="props">
        <td class="app-align-middle" v-bind:style="'background-color :' + FACILITIES_CONSTANT.manage_type[props.row.auto_manage_type].color">
          <p style="color: white; text-decoration: underline"
             @click="handleBtnManageStatus(props.row)"  class="apartment_name app-table-p app-cell-tooltip mb-0">
            {{FACILITIES_CONSTANT.manage_type[props.row.auto_manage_type].name}}
          </p>
        </td>
      </template>

      <template v-slot:body-cell-image_path="props">
        <td class="app-align-middle" style="display: flex;justify-content: center;">
          <img :src="props.row.image_path" v-bind:style="props.row.image_path ? '' : 'opacity:0'" style="width: 100px; height: 100px">
        </td>
      </template>

      <template v-slot:body-cell-status_disp_crowded="props">
        <td class="app-align-middle" v-bind:style="'background-color :' + getColorStatusDispCrowded(props.row.status_disp_crowded)">
          <p class=" app-table-p app-cell-tooltip mb-0" style="color: white">
            {{getStatusDispCrowded(props.row.status_disp_crowded)}}
          </p>
        </td>
      </template>

      <template v-slot:body-cell-status_disp_signage="props">
        <td class="app-align-middle" v-bind:style="'background-color :' + FACILITIES_CONSTANT.disp_signage[props.row.status_disp_signage].color">
          <p class=" app-table-p app-cell-tooltip mb-0" style="color: white">
            {{FACILITIES_CONSTANT.disp_signage[props.row.status_disp_signage].name}}
          </p>
        </td>
      </template>


      <template v-slot:body-cell-edit="props">
        <td class="app-align-middle text-center app-p-none">
          <button
            @click="handleBtnUpdateClick(props.row)"
            class="btn btn-primary"
          >
            {{ $t("common.edit") }}
          </button>
        </td>
      </template>

      <template v-slot:body-cell-list_plan="props">
        <td class="app-align-middle text-center app-p-none">
          <button
            class="btn btn-primary"
            @click="handleBtnPlanList(props.row)"
          >
            {{$t("facilities.filter_list_plan")}}
          </button>
        </td>
      </template>

    </app-basic-table>

    <!--    <create-component-->
    <!--      ref="modal_create"-->
    <!--      @onSuccess="handleCreateSuccess"-->
    <!--    ></create-component>-->

    <!--    <update-component-->
    <!--      @onSuccess="handleUpdateSuccess"-->
    <!--      ref="modal_update"-->
    <!--    ></update-component>-->

  </div>
</template>

<script>
  import AppBasicTable from "@components/_common/list/AppBasicTable";
  import AppDateRangePicker from "../../../components/forms/AppDateRangepicker";
  import {ENDPOINT} from "../../../constants/api";
  import {FACILITIES_CONSTANT} from "../../../constants/facilities";
  export default {
    name: "FacilityList",
    components: {
      "app-basic-table": AppBasicTable,
      AppDateRangePicker,
    },
    data() {
      return {
        idCache: null,
        paramGetList: {},
        filters: {
          apartment_id_equal: {
            name: "apartment_id",
            condition: "equal",
            label: this.$t("common.mansion")
          },
          serial_id_like: {
            name: "serial_id",
            condition: "like",
            label: this.$t("facilities.serial_id")
          },
          facility_id_equal: {
            name: "id",
            condition: "equal",
            label: this.$t("facilities.filter_facility_id")
          },
          status_disp_crowded_equal: {
            name: "status_disp_crowded",
            condition: "equal",
            label: this.$t("facilities.status_disp_crowed")
          },
        },
        columns: [
          {name: "order_disp_crowded", label: this.$t("common.number_no"), sortable: true, textAlign: 'text-center'},
          {name: "apartment_id", label: this.$t("common.mansion"), sortable: true, textAlign: 'text-center'},
          {name: "name", label: this.$t("facilities.filter_name"), sortable: true},
          {name: "serial_id", label: this.$t("facilities.serial_id"), sortable: true},
          {name: "image_path", label: this.$t("facilities.image"), textAlign: 'text-center'},
          {name: "status_disp_crowded", label: this.$t("facilities.filter_confirm_status_crowded"), sortable: true, textAlign: 'text-center'},
          {name: "auto_time_working", label: this.$t("facilities.filter_time_working"), sortable: true, textAlign: 'text-center'},
          {name: "auto_status", label: this.$t("facilities.filter_status_crowded"), sortable: true, textAlign: 'text-center'},
          {name: "auto_manage_type", label: this.$t("facilities.filter_update_status_crowded"), sortable: true, textAlign: 'text-center'},
          {name: "status_disp_signage", label: this.$t("facilities.filter_sign_display"), sortable: true, textAlign: 'text-center'},
          {name: "list_plan", label: this.$t("facilities.filter_list_plan"), textAlign: 'text-center'},
          {name: "edit", label: this.$t("common.edit"), textAlign: 'text-center'},
        ],
        meta: {
          apartments: [],
        },
        endPoint: ''
      };
    },
    watch : {
      "filters.apartment_id_equal.value" : function(value) {
          this.getParamsFilters({apartment_id : value})
      }
    },
    methods: {
      handleBtnCreateClick() {
        this.$router.push({name: this.ROUTES.ADMIN.FACILITY_CREATE})
      },
      handleBtnUpdateClick(entry) {
        this.$router.push({name: this.ROUTES.ADMIN.FACILITY_EDIT, params: {id: entry.id}})
      },
      handleBtnManageStatus(entry) {
        let routeManageStatus = this.$router.resolve({name: this.ROUTES.ADMIN.FACILITY_MANAGE_STATUS, params: {id: entry.id}});
        window.open(routeManageStatus.href, '_blank');
      },
      handleBtnPlanList(entry) {
        let routeBtnPlanList = this.$router.resolve({name: this.ROUTES.ADMIN.FACILITY_PLAN_LIST, params: {facility_id: entry.id}});
        window.open(routeBtnPlanList.href, '_blank');
      },
      handlerApartmentNameClick(entry) {
        let routeApartment = this.$router.resolve({name: this.ROUTES.ADMIN.APARTMENT_EDIT, params: {id: entry.apartment_id}});
        window.open(routeApartment.href, '_blank');
      },
      getStatusDispCrowded(status) {
        let data = FACILITIES_CONSTANT.disp_crowed.find(({id}) => id === status);
        return data.name
      },
      getColorStatusDispCrowded(status) {
        let data = FACILITIES_CONSTANT.disp_crowed.find(({id}) => id === status);
        return data.color
      },
      onResetAllSearch() {
        this.$router.push({name: this.$route.name,
          query: {
            'filters.status_disp_crowded.equal' : 'all',
            'perPage' : '99999',
            'filters.apartment_id.equal': 'all',
            'sortBy' : 'apartment_id',
          }
        })
      },
      onSearch() {
        // this.getTourSelectName();
      },
      async onSortRowTable(sortIndex) {
        await this.$request.post(this.ENDPOINT.FACILITY_RE_ORDER, {'sortIndex': sortIndex, 'apartment_id': this.filters.apartment_id_equal.value});
        this.$refs.basic_table.getEntries(true)
      },
      getApartmentName(apartmentId) {
        let apartment = this.meta.apartments.find(({id}) => id === apartmentId);
        if (apartment == undefined) {
          return '';
        }
        return apartment.name;
      },
      getParamsFilters(params) {
        this.$request.get(this.ENDPOINT.UI_HELPER_FACILITIES, params).then(res => {
          this.meta = {
            apartments: res.data.apartments,
            facilitiesName: res.data.facilities,
          }
          this.meta.apartments.unshift({
            id: "",
            name: ""
          })
          this.meta.facilitiesName.unshift({
            id: "",
            name: ""
          })
        })
      }
    },
    mounted: function () {
      // this.getParamsFilters({})
    },
  }
</script>

<style scoped>

</style>
